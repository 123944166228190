.title{
    background-color: rgba(242, 242, 242, 1);
    padding:10px 0px 10px 10px;
    border-left: 25px solid #ccc;
    margin-bottom: 30px;
    margin-top: 30px;
    span{
        float: right;
        margin-right: 20px;
        color:#40a9ff;
        cursor: pointer;
    }
}
.basis{
    display: flex;
    align-items: center;
}
.avatar{
    width: 150px;
    img{
        width: 130px;
        height: 130px;
        border-radius: 50%;
    }
}


.userMessage{
    flex:1;
    height: 100%;
}
