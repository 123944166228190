.wt-login{
    background-color:rgba(255,255,255,0.7);
    margin:0 auto;
    padding:30px 40px 50px 40px;
    width:350px;
}

.wt-login .login-form .login-form-button{
    width:100%;
}

.wt-login .login-form .login-form-forgot{
    float:right;
}

.wt-login .nav-logo{
    /* background:url("../img/logo.png"); */
    width: 96px;
    height: 46px;
    background-size:cover;
    margin: 0 auto 20px auto;
    cursor:pointer;
}

.wt-login .title{
    text-align: center;
    color: #789;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 30px;
}


.wt-changePassword{
    background-color:rgba(255,255,255,0.7);
    margin:0 auto;
    padding:30px 40px 50px 40px;
    width:550px;
}

.wt-changePassword .title{
    color: #789;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 45px;
}

.state{
    text-align: center;
    margin-left: 5px;
    width: 50px;
    /*height: 20px;*/
    /*background-color:rgba(255,255,255,0.7);*/
    /*display: inline-block;*/
    /*border:1px solid #eee;*/
    /*border-radius:5px;*/
}

.wt-auto-shadow{
    border: 1px solid #9e9e9d7d;
    box-shadow: 0 0 10px #9e9e9e;
}