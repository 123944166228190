.head{
  h2{
    font-weight:700;
    margin-top:12px;
  }

  padding:12px 24px 16px 24px;
  background-color:#fff;
  border: 1px solid #e8e8e8;
  transition: left 0.3s, transform 1.5s ,top 1.5s ;
}


.search{
  margin: 20px;
  padding: 24px 24px 24px 24px;

  background-color:#fff;
  border: 1px solid #e8e8e8;
  transition: left 0.3s, transform 1.5s ,top 1.5s ;
}

.main{
  margin: 20px;
  padding: 20px 24px 24px 24px;

  background-color:#fff;
  border: 1px solid #e8e8e8;
  transition: left 0.3s, transform 1.5s ,top 1.5s ;

  .toolbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    line-height: 64px;

    .toolbar_title{
      flex: 1 1;
      color: rgba(0,0,0,.85);
      font-size: 16px;
      line-height: 24px;
      opacity: .85;
    }

    .toolbar_option{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .toolbar_option_icon_button{
      margin-left:8px;
      font-size:16px;
      color:rgba(0,0,0,.65);
    }
  }
}

.box{
  margin: 20px;

  background-color:#fff;
  border: 1px solid #e8e8e8;
  transition: left 0.3s, transform 1.5s ,top 1.5s ;

  .toolbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    line-height: 64px;

    .toolbar_title{
      flex: 1 1;
      color: rgba(0,0,0,.85);
      font-size: 16px;
      line-height: 24px;
      opacity: .85;
    }

    .toolbar_option{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .toolbar_option_icon_button{
      margin-left:8px;
      font-size:16px;
      color:rgba(0,0,0,.65);
    }
  }
}
